import { atom } from "recoil";

export const empIdState = atom({
  key: "admin_emp_id",
  default: "",
});

export const dropdownOption = atom({
  key: "dropdownOption",
  default: "",
});

export const dropdownStatus = atom({
  key: "dropdownStatus",
  default: 0,
});

export const email = atom({
  key: "email",
  default: "",
});

export const userName = atom({
  key: "userName",
  default: "",
});
