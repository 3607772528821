import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import { LoadingOutlined } from "@ant-design/icons";
import VeroltLogo from "../assets/Logo/verolt-logo.png";
import { getOTPEmail, validateOtp } from "../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Input, message, Spin, Space, notification } from "antd";

const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const OtpValidation = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "" });
  const [showSpinner, setShowSpinner] = useState(false);
  const [otp, setOtp] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [hiddenEmailField, setHiddenEmailField] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { emailId, title } = location.state || {};

  useEffect(() => {
    if (emailId !== undefined && title !== undefined) {
      setEmail(emailId);
      setEmailSubmitted(true);
      setHiddenEmailField(true);
    }
  }, [emailId, title]);

  const handleEmailSubmit = async () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setShowSpinner(true);
    const userData = { email };
    try {
      const response = await getOTPEmail(userData);
      if (response?.status === 200) {
        if (response?.data.response === "success") {
          setShowSpinner(false);
          setEmailSubmitted(true);
        } else {
          notification.error({
            message: "Error",
            description: response?.data?.response || "Failed to send otp",
          });
        }
      } else {
        setEmail("");
        notification.error({
          message: "Error",
          description:
            response?.data?.response || "Failed to send otp, Please try again",
        });
      }
    } catch (error) {
      setShowSpinner(false);
      console.error("API error details:", error);
      notification.error({
        message: "Error",
        description: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setShowSpinner(false);
    }
  };

  const handleClear = () => setOtp("");

  const handleOtpChange = (value) => {
    setOtp(value);
    if (value.length !== 6) {
      message.warning("OTP should be 6 digits long.");
    }
  };

  const handleVerifyOtp = async () => {
    const newErrors = {};
    if (!otp) newErrors.otp = "Otp is required";
    else if (otp.length !== 6) newErrors.otp = "Please enter 6-digit OTP";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const userData = { email, user_otp: otp };
    setShowSpinner(true);
    try {
      const response = await validateOtp(userData);
      if (response.status === 200) {
        if (response?.data?.response === "otp validated") {
          notification.success({
            message: "Success",
            description: "OTP validated",
            showProgress: true,
          });
          setTimeout(
            () => navigate("/resetpassword", { state: { email } }),
            1000
          );
        } else {
          setOtp("");
          notification.error({
            message: "Error",
            description: response?.data?.response || "Incorrect otp",
          });
          setOtp("");
        }
      } else if (response?.status === 406) {
        setOtp("");
        notification.error({
          message: "Error",
          description:
            `${
              response?.data?.response.charAt(0).toUpperCase() +
              response?.data?.response.slice(1)
            }` || "Invalid OTP",
          showProgress: true,
        });
      } else {
        setShowSpinner(false);
        notification.error({
          message: "Error",
          description: "OTP verification failed. Please try again",
        });
        navigate("/verify-otp");
      }
    } catch (error) {
      setShowSpinner(true);
      console.error("An error occurred:", error);
      notification.error({
        message: "Error",
        description: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setShowSpinner(false);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!newEmail) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
    } else if (isValidEmail(newEmail)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address",
      }));
    }
  };

  return (
    <Spin
      spinning={showSpinner}
      tip="Please wait.."
      indicator={<LoadingOutlined size={30} spin />}
    >
      <div className="fs-forgot">
        <Navbar link={""} />
        <div className="row fs-otp-validation-container d-flex align-items-center justify-content-center m-auto">
          <div className="col-1 col-md-3 col-xl-4"></div>
          <div className="col-10 col-md-6 col-xl-4">
            <Card
              className={`fs-otp-validation-card text-center bg-dark text-white card rounded-2 border-danger ${
                emailSubmitted ? "" : "mt-5"
              } `}
            >
              <img
                className="d-block m-auto"
                src={VeroltLogo}
                width="60"
                height="50"
                alt="logo"
              />
              <h5 className="fw-normal text-danger mb-4">VeSense</h5>
              <h4 className="fw-bold">
                {title
                  ? title
                  : emailSubmitted
                  ? `Verify OTP`
                  : `Forgot Password`}
              </h4>

              {!emailSubmitted ? (
                <div>
                  <div className="form-group text-start mt-3 ">
                    <label className="form-label fs-6">Email Address</label>
                    <Input
                      id="email"
                      name="email"
                      className={`form-control ${
                        errors.email ? "border-3 border-danger" : ""
                      }`}
                      placeholder="Please enter your Email"
                      value={email}
                      onChange={handleEmailChange}
                      disabled={emailSubmitted}
                    />
                    {errors.email && (
                      <div className="text-danger fw-bold mt-1">
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-danger btn-block w-100 mt-3 py-2 px-3 fw-bold"
                    onClick={handleEmailSubmit}
                  >
                    Get OTP
                  </button>
                </div>
              ) : (
                <div className="text-start mt-4">
                  <div
                    className={`form-group ${hiddenEmailField && "u-hidden"}`}
                  >
                    <label className="form-label fs-6">Email Address</label>
                    <Input value={email} disabled />
                  </div>
                  <div className="form-group mt-3">
                    <label className="form-label fs-6">OTP</label>
                    <Input.OTP
                      value={otp}
                      length={6}
                      autoFocus
                      className="w-100"
                      inputType="numeric"
                      onChange={handleOtpChange}
                      onKeyDown={(e) => {
                        if (e.key === "Backspace" && otp.length <= 6) {
                          setOtp((prevOtp) => prevOtp.slice(0, -1));
                        }
                      }}
                    />
                  </div>
                  <Space className="d-flex justify-content-between mt-4">
                    <button
                      className={`btn btn-sm  p-2 ${
                        otp.length !== 6 ? "btn-secondary" : "btn-danger"
                      }`}
                      onClick={handleVerifyOtp}
                      type="submit"
                      disabled={otp.length !== 6}
                    >
                      Verify OTP
                    </button>
                    <button
                      className="btn btn-sm btn-outline-danger p-2"
                      onClick={handleClear}
                      type="submit"
                    >
                      Clear OTP
                    </button>
                  </Space>
                </div>
              )}
            </Card>
          </div>
          <div className="col-1 col-md-3 col-xl-4"></div>
        </div>
      </div>
    </Spin>
  );
};

export default OtpValidation;
