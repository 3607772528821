import React from "react";
import Login from "./Login";
import Navbar from "../components/Navbar/Navbar";

const LoginContainer = () => {
  return (
    <div className="row mx-0 text white">
      <div className="col-1"></div>
      <div className="col-10 col-xl-4 text-white mt-3 d-flex flex-column justify-content-center">
        <div className="display-5 text-start">
          <span className="text-danger fw-bold">Empowering </span>People to
          Engineer <span className="text-danger fw-bold"> Brilliance</span>
        </div>
        <h4 className="text-start mt-5 text-white">Welcome to VeSense</h4>
      </div>
      <div className="col-1"></div>
      <div className="col-1"></div>
      <div className="col-10 col-xl-4 bg-dark text-white card rounded-2 border-danger p-4 p-md-3 mt-3">
        <Login />
      </div>
      <div className="col-1"></div>
    </div>
  );
};

const LandingPage = () => {
  return (
    <div className="fs-landing-page pb-5">
      <Navbar link={"/"} />
      <LoginContainer />
    </div>
  );
};

export default LandingPage;
