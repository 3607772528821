import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useRecoilState } from "recoil";
// import { dropdownOption, dropdownStatus } from "./atoms";
import { dropdownOption, dropdownStatus } from "../../states/state";

const menuItems = [
  { label: "Admin", value: "1" },
  { label: "Automotive", value: "2" },
  { label: "Data Engineering", value: "3" },
  { label: "Finance", value: "4" },
  { label: "HR", value: "5" },
  { label: "HMI", value: "6" },
  { label: "IT Support", value: "7" },
  { label: "Other", value: "8" },
];

export default function UserDropdown() {
  const [selected, setSelected] = useState("Select Department *");
  const [dropdownValue, setDropdownValue] = useRecoilState(dropdownOption); // Bind dropdownOption state
  const [status, setStatus] = useRecoilState(dropdownStatus); // Bind dropdownStatus state

  const handleSelect = (eventKey) => {
    const selectedItem = menuItems.find((item) => item.value === eventKey);
    const newLabel = selectedItem ? selectedItem.label : "Select Department";

    // Update the local state
    setSelected(newLabel);

    // Update Recoil state with the selected option's value
    setDropdownValue(newLabel);

    // Update the status to 1 when a valid option is selected
    if (newLabel !== "Select Department") {
      setStatus(1);
    }
  };

  console.log(status);

  return (
    <div className="dropdown-main">
      <Dropdown onSelect={handleSelect} className="dropdown-component">
        <Dropdown.Toggle id="dropdown-basic" className="dropdown-list">
          {selected}
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu">
          {menuItems.map((item) => (
            <Dropdown.Item key={item.value} eventKey={item.value}>
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
