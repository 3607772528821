import React from "react";
import logo1 from "../../assets/Logo/verolt.png";

export default function FeedbackPageFooter() {
  return (
    <div className="footer-container">
      <div className="footer-main">
        <img src={logo1} className="footer-image" />
      </div>
    </div>
  );
}
