import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logo/verolt.png";
import { MdLogout } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function UserPageNavbar() {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();

  // Function to handle logout confirmation
  const handleLogout = () => {
    setShowLogoutModal(true); // Show the logout confirmation modal
  };

  // Function to navigate to home when user confirms logout
  const confirmLogout = () => {
    setShowLogoutModal(false); // Hide the modal
    navigate("/"); // Navigate to the home page
  };

  return (
    <div className="navabr-container">
      <div className="navbar-main">
        <img src={logo} className="navbar-logo" alt="Verolt Logo" />
        <MdLogout className="navbar-button" onClick={handleLogout} />
      </div>

      {/* Logout Confirmation Modal */}
      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmLogout}>
            Yes, Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
