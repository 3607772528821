import React, { useState } from "react";
import { Spin, notification, Card, Col, Row, Input } from "antd";
import { useSetRecoilState } from "recoil";
import { empIdState, userName } from "../states/state";
import { Link, useNavigate } from "react-router-dom";
import VeroltLogo from "../assets/Logo/verolt-logo.png";
import { adminLogin, getOTPEmail } from "../services/api";
import {
  EyeInvisibleFilled,
  EyeFilled,
  LoadingOutlined,
} from "@ant-design/icons";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [showSpinner, setShowSpinner] = useState(false);
  const [, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const setEmpId = useSetRecoilState(empIdState);
  const setUserName = useSetRecoilState(userName); // Recoil state for storing username

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setShowSpinner(true); // Show spinner at the beginning

    try {
      console.log("Attempting to call adminLogin with formData:", formData); // Initial log

      const response = await adminLogin(formData);
      console.log("Response from server:", response); // Confirming response data

      if (response.status === 200 && response.data.code === 0) {
        const { emp_id, name, role } = response.data;
        setEmpId(emp_id);
        setUserName(name); // Save the name in Recoil state
        sessionStorage.setItem("admin_emp_id", emp_id);
        sessionStorage.setItem("isAuthenticated", "true");

        notification.success({
          message: "Success",
          description: "Login Successful",
          showProgress: true,
          pauseOnHover: true,
        });

        // Navigate based on role after a timeout
        setTimeout(() => {
          if (role === "admin") {
            navigate("/dashboard");
          } else if (role === "user") {
            navigate("/feedbackpage");
          }
        }, 500); // Wait for 500ms before navigating
      } else if (
        response.status === 401 &&
        response.data.response === "update password" &&
        response.data.code === 1
      ) {
        console.log("Routing to OTP verification page");

        // Directly set the loading state to false here
        setShowSpinner(true); // Keep the spinner visible

        // Use a timeout to ensure the spinner shows before navigating
        setTimeout(async () => {
          try {
            // Send email to getOTPEmail for OTP generation
            const otpResponse = await getOTPEmail({ email: formData.email });
            console.log("OTP email sent successfully:", otpResponse);

            notification.info({
              message: "OTP Required",
              description: "Please verify your OTP to proceed",
            });

            // Navigate to the OTP verification page
            navigate("/verify-otp", {
              state: { emailId: formData.email, title: "Verify OTP" },
            });
          } catch (otpError) {
            console.error("Failed to send OTP email:", otpError);
            notification.error({
              message: "OTP Error",
              description: "Failed to send OTP email. Please try again!",
            });
          } finally {
            setShowSpinner(false); // Hide the spinner after the process is complete
          }
        }, 500); // This delay allows the spinner to be visible before navigating
      } else {
        setShowSpinner(false);
        setFormData((prevData) => ({ ...prevData, password: "" }));
        notification.error({
          message: "Error",
          description: response?.data?.response
            ? capitalizeFirstLetter(response.data.response)
            : "Login failed",
        });
      }
    } catch (error) {
      console.error("Error caught in catch block:", error); // Log any catch block errors
      setShowSpinner(false);
      notification.error({
        message: "Error",
        description: "Login failed. Please try again!",
      });
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  return (
    <Spin
      spinning={showSpinner}
      indicator={<LoadingOutlined size={40} spin />}
      size="large"
    >
      <div className={`fs-login ${showSpinner && "blur"}`}>
        <Row className="loginContainer">
          <Col span={24}>
            <Card className="loginCard border-0 bg-transparent text-white">
              <img src={VeroltLogo} width="60" height="50" alt="logo" />
              <h4 className="text-center fw-normal fs-4 text-danger">
                VeSense
              </h4>
              <h2 className="text-center">Login</h2>
              <form onSubmit={handleLogin}>
                <div className="form-group text-start mt-3">
                  <label className="form-label fx-6">Email</label>
                  <Input
                    size="large"
                    id="email"
                    name="email"
                    value={formData.email}
                    className={`form-control ${
                      errors.email ? "border-3 border-danger" : ""
                    }`}
                    placeholder="Please enter your Email"
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="text-danger fw-bold mt-1">
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="form-group text-start mt-3">
                  <label className="form-label fs-6">Password</label>
                  <Input.Password
                    id="password"
                    name="password"
                    value={formData.password}
                    className={`input-password form-control ${
                      errors.password ? "border-3 border-danger" : ""
                    }`}
                    placeholder="Please enter your Password"
                    onChange={handleChange}
                    iconRender={(visible) =>
                      visible ? (
                        <EyeFilled
                          className="passwordIcon"
                          onClick={() => setShowPassword(true)}
                        />
                      ) : (
                        <EyeInvisibleFilled
                          className="passwordIcon"
                          onClick={() => setShowPassword(false)}
                        />
                      )
                    }
                  />
                  {errors.password && (
                    <div className="text-danger fw-bold mt-1">
                      {errors.password}
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-end mt-3 mb-3">
                  <Link
                    to="/forgotpassword"
                    className="text-danger fw-bold text-decoration-none"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <button
                  type="submit"
                  className="btn btn-lg btn-danger rounded-3 fs-5 btn-sm w-100"
                  style={{
                    height: "45px",
                    transition: "background-color 1s ease",
                  }}
                >
                  Login
                </button>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Login;
