import React from "react";
import UserPageNavbar from "./UserPageNavbar";
import UserFormPage from "./UserFormPage";
import FeedbackPageFooter from "./FeedbackPageFooter";

export default function FeedbackPageMain() {
  return (
    <div>
      <UserPageNavbar />
      <UserFormPage />
      <FeedbackPageFooter />
    </div>
  );
}
